import React, { useEffect, useState } from 'react'
import { StructuredText } from 'react-datocms'
import Header from '../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Layout from '../components/layout'

const Posts = ({ data }) => {
  console.log(data)
  function scrollToForm() {
    document.querySelector("#form").scrollIntoView({ behavior: "smooth" });


  }


  const [text, setText] = useState("Register")
  useEffect(() => {

    if (window.innerWidth > 768) {
      setText("REGISTER YOUR INTEREST")
    }
  }, [])
  useEffect(() => {
    





    const html = document.querySelector("html")
    html.style.overflow = "scroll"
    const body = document.querySelector("body")
    body.style.overflow = "scroll"






    const register = document.querySelector("#register")
    register.classList.remove("hidden")
  



  }, [])

  
  return (
    <Layout>
      <div>
        <Header text={text} textTop="News" handleClick={scrollToForm} logo={"noLoad"} middle={"no-o-p-0"} loaderClass={"no-load"} />
        <div className="flex flex-c flex-c-h ">
          {data.post.posts.map((post, index) => {
            switch (post.model.apiKey) {
              case "headline":

                return (
                  <div className='flex flex-c-h p-l-15 p-r-15'>

                    <div className="p-t-165 br m-w-850 ">
                      <h1 className="large large-br ln">{post.headline}</h1>
                    </div>
                  </div>
                )

              case "vertical_image":

                return (
                  <div className='flex flex-c-h m-b-80 p-l-15 p-r-15 m-b-80-br'>
                    <div className="m-w-650">
                      <GatsbyImage image={post.image.gatsbyImageData} className=" w-100p " />
                    </div>
                  </div>
                )

              case "largetext":
                return (
                  <div className="flex flex-c-h m-b-70 p-l-15  p-r-15">
                    <div className="m-w-650">
                      <h3  className="p-b-30-s">
                        <StructuredText data={post.text2} />
                      </h3>
                    </div>
                  </div>)


              case "smalltext":
                return (<div className=" m-b-110 flex flex-c-h  p-l-15 p-r-15  m-b-110-br" >
                  <div className="Acuman-lowercase-line-height-16px white struct half m-w-650 link-style" style={{color:"#F2F2F0"}}>
                    <StructuredText data={post.text1} />
                  </div>

                </div>);

              case "horizontal_image":
                return (<div className=" m-b-120  p-t-20 p-t-20-br  m-b-120-br " >
                  <div className=" w-100p">
                    <GatsbyImage image={post.image.gatsbyImageData} className=" w-100 h-100vh br-100vh" />
                  </div>

                </div>);
              case "button":
                return (<div className='flex flex-c-h m-b-120 p-l-15 p-r-15 m-b-120-br' >
                  <a href={post.link} className="submit flex-c flex flex-c-v flex-c-h" style={{ backgroundColor: "#C0BEB2" }}>
                    <div className=" text ">
                      <span >  {post.text}</span>

                    </div>

                  </a>
                </div>);
              case "title":
                  return(<div className="flex flex-c-h m-b-10 p-l-15  p-r-15">
                      <h6 className="text white mb15">{post.title}</h6>
                    </div>

                  )



              default:
                return null



            }

          })}

        </div>
        <div
          id="form-section"
          className="section-100vh padding-190px form-section"
          style={{ backgroundColor: "#ACAB9A" }}
        >
          <div>
            <div className="pad-20-b">
              <h4 className="acumin-11px white">SALES OFFICE & INFORMATION</h4>
            </div>
            <div>
              <p className="Acuman-lowercase white weight-400">
                17-21 Longland Street, Newstead Q 4006
                <br />
                By private appointment only.
                <br />
                info@bidenewstead.com.au | 0499 899 558
              </p>
            </div>
          </div>







          <form
            id="form"
            action="/thank-you"
            className="form"
            name="contact"
            method="POST"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-inner">
              <div className="width-50 left-40" id="half">
                <div className="input-wrapper">
                  <label>
                    <h5 className="acumin-11px white justify-left">
                      First Name*
                    </h5>
                    <input
                      required
                      tabIndex="1"
                      className="text-input"
                      name="firstName"
                      type="text"
                      placeholder="Morgan "
                    />
                  </label>
                </div>
                <div className="input-wrapper">
                  <label>
                    <h5 className="acumin-11px white justify-left"> Email*</h5>
                    <input
                      required
                      tabIndex="3"
                      className="text-input"
                      name="email"
                      type="text"
                      placeholder="morganlongland@mail.com"
                    />
                  </label>
                </div>
                <div className="input-wrapper">
                  <label>

                    <h5 className="acumin-11px white justify-left">
                      PostCode*
                    </h5>
                    <input
                      required
                      tabIndex="5"
                      className="text-input"
                      name="postCode"
                      type="text"
                      placeholder="4321"
                    />
                  </label>
                </div>
              </div>
              <div className="width-50 right-40" id="half">
                <div className="input-wrapper">
                  <label>
                    <h5 className="acumin-11px white justify-left">Surname*</h5>
                    <input
                      required
                      tabIndex="2"
                      className="text-input"
                      name="surname"
                      type="text"
                      placeholder="Longland"
                    />
                  </label>
                </div>
                <div className="input-wrapper">
                  <label>
                    <h5 className="acumin-11px white justify-left"> Phone*</h5>
                    <input
                      required
                      tabIndex="4"
                      className="text-input"
                      name="Phone"
                      type="text"
                      placeholder="0412 345 678"
                    />
                  </label>
                </div>
                <div className="input-wrapper">
                  <div>
                    <label>
                      <h5 className="acumin-11px white justify-left">
                        WHAT ARE YOU ENQUIRING ABOUT?
                      </h5>
                      <div className="drop-down-wrapper">
                      <select
                            required

                            name="WHAT ARE YOU ENQUIRING ABOUT?"
                            className="text-input placeholder "
                            defaultValue="1 Bedroom Residence"
                          >
                  
                            <option value="1 Bedroom" className="">1 Bedroom Residences</option>
                            <option value="2 Bedroom">2 Bedroom Residences</option>
                            <option value="3 Bedroom">3 Bedroom Skyhomes</option>
                             
                          </select>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          className="arrow dark-putty-svg abosulte"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5455 8.74583L9.73499 11.473L9.73499 5.41553L9.73499 5.31553L9.63499 5.31553L9.05063 5.31553L8.95063 5.31553L8.95063 5.41553L8.95063 11.473L6.14007 8.74583L5.97043 8.58123L5.97043 8.8176L5.97043 9.55471L5.97043 9.59702L6.0008 9.62648L9.27317 12.8017L9.34281 12.8693L9.41245 12.8017L12.6848 9.62648L12.7152 9.59702L12.7152 9.55471L12.7152 8.8176L12.7152 8.58123L12.5455 8.74583Z"
                            strokeWidth="0.2"
                          />
                        </svg>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-wrapper">
              <button type="submit" className="submit text " style={{ backgroundColor: "#ACAB9A" }}>
                submit Details
              </button>
            </div>
            <div className="p-b-25 flex flex-c-h p-t-70-br">
              <h5 className="small-footer-text white center-text">Proudly developed by</h5>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default Posts


export const query = graphql`
query post($slug: String!) {
    post:datoCmsBlogPost(slug: { eq: $slug }) {
       
          id
          posts {
            ... on DatoCmsHeadline {
              id
              model {
                apiKey
              }
              originalId
              headline
            }
            ... on DatoCmsLargetext {
              id
              
              model {
                apiKey
              }
              originalId
             text2: text {
                value
              }
              
            }
            ... on DatoCmsSmalltext {
              id
              
              
              model {
                apiKey
              }
              originalId
              text1: text {
                value
              }
            }
            ... on DatoCmsButton {
              id
              text
              model {
                apiKey
              }
              originalId
              link
            }
            ... on DatoCmsVerticalImage {
              id
              model {
                apiKey
              }
              originalId
              image {
                gatsbyImageData
              }
            }
            ... on DatoCmsHorizontalImage {
              id
              model {
                apiKey
              }
              originalId
              image {
                gatsbyImageData
              }
            }
            ... on DatoCmsTitle{
              id
              
              
              model {
                apiKey
              }
              originalId
              title
            }
          
        
      }
          
        
}
}
`